import React from "react"
import Layout from "../components/layout"
import ProductDetail from "../components/product_detail"
import TechnicalData from "../components/product_tech_data"
import truck_mounted from "../../public/static/images/products/truck_mounted_anti_smog_gun.jpg"

function TruckMounted() {

  const prodDetail = {
    "name": "Truck Mounted Anti Smog Gun",
    "detail": {
      "about" : ["Anti-smog gun is a canon shaped device that sprays atomized water droplets in the air."],
      "advantages": ["Smog Gun vehicle is a specialized tool for disinfection of epidemic- containment and potentially contaminated areas (epidemic areas); it has become an important technical means to stop the spread of virus. The use of disinfection vehicle can greatly improve the disinfection and virus killing effect in a large area, greatly improve the disinfection efficiency and evenly disinfect, effectively blocking the virus transmission."]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Capacity",
        "Material",
        "Drive",
        "Pump",
        "Working Pressure",
        "Cannon Gun Operation",
        "Truck Chassis",
        "Hydraulic Motor",
        "Spraying Water Ring",
        "Material of Water Ring"
      ],
      "column2": [
        "IEASG-9LCH",
        "1KL upto 12KL",
        "Mild Sheet / Stainless Steel",
        "Prime Mover (PTO)",
        "High Pressure Triplex Piston",
        "70 LPM 130 Bar",
        "Hydraulic / Pneumatic",
        "3 upto 25 Ton",
        "Denfoss or Equivalent",
        "Single Ring",
        "Stainless Steel"
      ]
    }
  }

  return (
    <Layout>
    <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={truck_mounted} />

    {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default TruckMounted
